import React from 'react'
import {Card, CardImg, CardImgOverlay, CardTitle, CardText} from "reactstrap";
import './SurveyTile.css'

export default function SurveyTile(props){
    return <div className='SurveyTile'>
        <Card inverse>
            <CardImg
                alt={props.imgAlt}
                src={props.imgSrc}
                width="100%"
                onClick={()=>{if(props.isReady) {props.onClick()}}}
            />
            <CardImgOverlay>
                <CardTitle tag="h5">
                    {props.title}
                </CardTitle>
                <CardText className={props.isReady ? '' : 'text-muted'}>
                    {props.isReady ? 'Participate in online surveys' : 'Loading...'}
                </CardText>
            </CardImgOverlay>
        </Card>
    </div>
}
