import axios from "axios";
import {API_URL} from "../config";
import {logAmplitudeCompletedCampaignEvent} from "../amplitude";

export const getAppApi = (store, authUser, unauthorizedCallback) => {
    console.log('App api created')
    const api = axios.create({ baseURL: API_URL });
    if(authUser && authUser.access_token){
        api.defaults.headers.common['Authorization'] = 'Bearer ' + authUser.access_token;
    }
    api.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        console.log('error in interceptor response')
        console.log(error)
        if (error.response && 401 === error.response.status) {
            console.log('clearing the user...')
            api.defaults.headers.common['Authorization'] = null;
            unauthorizedCallback()
        } else {
            console.log('Rejecting promise in response')
        }
        return Promise.reject(error.response ? error.response : error);
    });

    function getUserData() {
        return api.get(`${API_URL}/app/v1/user`)
    }

    function updateUserData(data) {
        return api.patch(`${API_URL}/app/v1/user`, data)
    }

    function getBalance() {
        return api.get(`${API_URL}/app/v1/balance`)
    }

    function getAllAccountInfo(cardNumber) {
        return api.get(`${API_URL}/app/v1/pub/loyalty/${cardNumber}`)
    }

    function getPossibleGiftCards() {
        return api.get(`${API_URL}/app/v1/giftCard/list`)
    }

    function submitGiftCardOrder({givenName,familyName,email,amount,appUserId,sku}) {
        const data = {
            givenName:givenName,
            familyName:familyName,
            email:email,
            amount:amount,
            appUserId:appUserId,
            sku:sku
        }
        return api.post(`${API_URL}/app/v1/user/giftCard`, data)

    }

    function getExperiences(){
        return api.get(`${API_URL}/app/v1/mainFeed`)
    }

    function submitAnswers(campaign, answers, timeSpent) {
        logAmplitudeCompletedCampaignEvent(campaign.name, campaign.totalValue)
        const data = {
            responses: answers,
            timeSpent: timeSpent
        }
        return api.post(`${API_URL}/app/v1/user/campaignCompletion`, data)
    }

    function trackCtaClick(campaignId) {
        return api.post(`${API_URL}/app/v1/user/campaign/${campaignId}/ctaclick`, {})
    }

    function uploadProfileImage(file, w, h) {
        let data = new FormData();
        data.append('file', file, file.name);
        data.append('folderPath', 'images');
        return api.post(`https://us-central1-adjoy-main.cloudfunctions.net/cropAndUploadImage?w=${w}&h=${h}&x=0&y=0&ar=1`, data, {
            headers: {
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
            },
            transformRequest: (data, headers) => {
                delete headers.common['Authorization'];
                delete headers.common['Accept'];
                return data;
            }
        })
    }
        return {
            getUserData,
            getBalance,
            getAllAccountInfo,
            getPossibleGiftCards,
            submitGiftCardOrder,
            submitAnswers,
            updateUserData,
            uploadProfileImage,
            getExperiences,
            trackCtaClick
        }
}