import React, {useEffect} from 'react'
import {
    Button,
    Nav,
    NavItem,
    NavLink,
    NavbarText,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
    Media
} from "reactstrap";
import logoImage from "./assets/dabbl-logo-orange.png";
import {Link} from "react-router-dom";
import {useAuth} from "./contexts/auth";
import user from "./assets/user.png";

export default function Navigation () {
    const {api, authUser, clearUser, balance, userInfo, setUserInfo, setBalance } = useAuth()
    useEffect( () => {
        if (!authUser) return
        if (!userInfo){
            api.getUserData().then(res => setUserInfo( res.data.data))
        }
        api.getBalance().then(res => setBalance(res.data.data ? Number(res.data.data.buffer) : 0))
    }, [authUser, userInfo]);
    if (!authUser || !userInfo) return null
    console.log('render navigation')
    return (
            <>
        <Nav>
            <NavItem>
                <img top="true" style={{height: '30px', margin: '5px', display: 'block'}} src={logoImage} alt="Logo" />
            </NavItem>
            <NavItem>
                <Link active="true" to="/" className='nav-link'>User Feed</Link>
            </NavItem>

            <NavbarText className='ms-auto fw-bold'>
                {balance && `Balance: ${(Number(balance)*1000).toFixed(0)}pts`}
            </NavbarText>
            <NavbarText className='ms-end'>
            </NavbarText>
            <UncontrolledDropdown inNavbar nav className='ms-auto me-3'>
                <DropdownToggle caret nav>
                    {userInfo && <>
                        <Media left middle object src={userInfo.profileImage || user} className='rounded-circle'
                                          style={{maxHeight: '32px', maxWidth: '32px', clipPath: 'circle'}} alt={''}/>

                    </>
                        }
                </DropdownToggle>
                <DropdownMenu style={{right:'0'}}>
                    <DropdownItem>
                        <Link active="true" to="/account" className='nav-link'>Account</Link>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                        <NavLink href="#" onClick={(e) => clearUser()}>
                            Sign Out
                        </NavLink>
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </Nav>

                <hr className='p-0 m-0' />
            </>
        )
}