export const REGEXS = {
    DIGITS: /[0-9]/
}

export const KEY_CODES = {
    BACKSPACE: 8,
    ARROW_LEFT: 37,
    ARROW_RIGHT: 39
}

export const demoCampaign = {
    id: '0',
    totalValue: 0.35,
    brandName: "Snacks",
    brandColor: "#FF00FF",
    feedimageurl: "https://storage.googleapis.com/adjoy-app-assets/images/83b36800-6bf2-11e7-8f20-37b8db81a4c2/medium.png",
    totalEng: 28,
    completiontext: "Thank you for spending time with us, we hope you had fun! \nLook for us at your favorite store!",
    brandLogoURL: "https://storage.googleapis.com/adjoy-app-assets/images/7cad28c0-8088-11e7-918c-63c0a55967e9/large.jpg",
    brandLogoID: "0b3a9b10-6bf1-11e7-8f20-37b8db81a4c2",
    brandLogoThumbURL: "https://storage.googleapis.com/adjoy-app-assets/images/7cad28c0-8088-11e7-918c-63c0a55967e9/thumbnail.jpg",
    feedtext: "",
    brandId: "e7afcf8f-dcca-4daf-aa79-db48d3105778",
    name: "Snack On This",
    introimageurl: "",
    introtext: "",
    campaignComplete:false,
    engagements: [
        {
            id: '1',
            ordinal: 1,
            kind: 'choose',
            firstImageURL: 'https://storage.googleapis.com/adjoy-app-assets/images/cfeec750-7336-11e7-871f-ff691b86f663/large.jpg',
            // secondImageURL: 'https://storage.googleapis.com/adjoy-app-assets/images/e9571030-6bb1-11e7-8396-19a45ed6a768/large.png',
            // videoURL: 'https://storage.googleapis.com/adjoy-app-assets/videos/a3589810-7336-11e7-9afd-3bdc82ce8831/original.mp4',
            text: 'I feel good about shopping local because...',
            options: [
                {answer: 'I am supporting my community', id: '1'},
                {answer: 'I am reducing my environment footprint', id: '2'},
                {answer: 'I know where my food came from', id: '3'},
                {answer: 'All of the above!', id: '4'},
            ]
        },
        // {
        //   id: '2',
        //   ordinal: 2,
        //   kind: 'this_or_that',
        //   firstImageURL: 'https://storage.googleapis.com/adjoy-app-assets/images/118bf570-7337-11e7-871f-ff691b86f663/large.jpg',
        //   secondImageURL: 'https://storage.googleapis.com/adjoy-app-assets/images/1ad60a80-7337-11e7-871f-ff691b86f663/large.jpg',
        // },
        // {
        //   id: '3',
        //   ordinal: 1,
        //   kind: 'choose',
        //   firstImageURL: 'https://storage.googleapis.com/adjoy-app-assets/images/cfeec750-7336-11e7-871f-ff691b86f663/large.jpg',
        //   secondImageURL: 'https://storage.googleapis.com/adjoy-app-assets/images/e9571030-6bb1-11e7-8396-19a45ed6a768/large.png',
        //   videoURL: 'https://storage.googleapis.com/adjoy-app-assets/videos/a3589810-7336-11e7-9afd-3bdc82ce8831/original.mp4',
        //   text: 'I feel good about shopping local because...',
        //   options: [
        //     {answer: 'I am supporting my community', id: '1'},
        //     {answer: 'I am reducing my environment footprint', id: '2'},
        //     {answer: 'I know where my food came from', id: '3'},
        //     {answer: 'All of the above!', id: '4'},
        //   ]
        // },
        // {
        //   id: '4',
        //   ordinal: 2,
        //   kind: 'this_or_that',
        //   firstImageURL: 'https://storage.googleapis.com/adjoy-app-assets/images/118bf570-7337-11e7-871f-ff691b86f663/large.jpg',
        //   secondImageURL: 'https://storage.googleapis.com/adjoy-app-assets/images/1ad60a80-7337-11e7-871f-ff691b86f663/large.jpg',
        // },
        // {
        //   id: '5',
        //   ordinal: 1,
        //   kind: 'choose',
        //   firstImageURL: 'https://storage.googleapis.com/adjoy-app-assets/images/cfeec750-7336-11e7-871f-ff691b86f663/large.jpg',
        //   secondImageURL: 'https://storage.googleapis.com/adjoy-app-assets/images/e9571030-6bb1-11e7-8396-19a45ed6a768/large.png',
        //   videoURL: 'https://storage.googleapis.com/adjoy-app-assets/videos/a3589810-7336-11e7-9afd-3bdc82ce8831/original.mp4',
        //   text: 'I feel good about shopping local because...',
        //   options: [
        //     {answer: 'I am supporting my community', id: '1'},
        //     {answer: 'I am reducing my environment footprint', id: '2'},
        //     {answer: 'I know where my food came from', id: '3'},
        //     {answer: 'All of the above!', id: '4'},
        //   ]
        // },
        // {
        //   id: '6',
        //   ordinal: 2,
        //   kind: 'this_or_that',
        //   firstImageURL: 'https://storage.googleapis.com/adjoy-app-assets/images/118bf570-7337-11e7-871f-ff691b86f663/large.jpg',
        //   secondImageURL: 'https://storage.googleapis.com/adjoy-app-assets/images/1ad60a80-7337-11e7-871f-ff691b86f663/large.jpg',
        // },

        // {
        //   id: '3',
        //   ordinal: 3,
        //   kind: 'ratable',
        //   videoURL: 'https://storage.googleapis.com/adjoy-app-assets/videos/bc8442c0-86b0-11e7-a4b5-a93881cb0f79/original.mp4',
        //   text: 'Grown fresh. Grown locally. Grown for Shoprite.',
        // },
        // {
        //   id: '4',
        //   ordinal: 4,
        //   kind: 'ratable',
        //   // kind: 'this_or_that',
        //   // firstImageURL: 'https://storage.googleapis.com/adjoy-app-assets/images/b9020430-6bb1-11e7-8396-19a45ed6a768/large.png',
        //   // secondImageURL: 'https://storage.googleapis.com/adjoy-app-assets/images/e9571030-6bb1-11e7-8396-19a45ed6a768/large.png',
        //   videoURL: 'https://storage.googleapis.com/adjoy-app-assets/videos/a3589810-7336-11e7-9afd-3bdc82ce8831/original.mp4',
        //   // videoURL: 'https://storage.googleapis.com/adjoy-app-assets/videos/bc8442c0-86b0-11e7-a4b5-a93881cb0f79/original.mp4',
        //   text: 'Grown fresh. Grown locally. Grown for Shoprite.',
        // },
        // {
        //   id: '5',
        //   ordinal: 5,
        //   kind: 'ratable',
        //   // kind: 'this_or_that',
        //   // firstImageURL: 'https://storage.googleapis.com/adjoy-app-assets/images/b9020430-6bb1-11e7-8396-19a45ed6a768/large.png',
        //   // secondImageURL: 'https://storage.googleapis.com/adjoy-app-assets/images/e9571030-6bb1-11e7-8396-19a45ed6a768/large.png',
        //   // videoURL: 'https://storage.googleapis.com/adjoy-app-assets/videos/a3589810-7336-11e7-9afd-3bdc82ce8831/original.mp4',
        //   videoURL: 'https://storage.googleapis.com/adjoy-app-assets/videos/bc8442c0-86b0-11e7-a4b5-a93881cb0f79/original.mp4',
        //   text: 'Grown fresh. Grown locally. Grown for Shoprite.',
        // },
    ]
};