import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import SurveyPollfish from "./SurveyPollfish";
import SurveyTheoremReach from "./SurveyTheoremReach";

export default function Surveys() {
    return <Container className='mx-2'>
        <Row>
            <Col xs={12} sm={8} lg={4}>
                <SurveyPollfish/>
            </Col>
            <Col xs={12} sm={8} lg={4}>
                <SurveyTheoremReach/>
            </Col>
        </Row>
    </Container>
}
