/*
  Configures the App Environment - i.e. environment and urls
*/

// Used to create a Basic OAuth Token
export const APPLICATION_ID     = process.env.REACT_APP_APPLICATION_ID     || 'mynfo_consumer';
export const APPLICATION_SECRET = process.env.REACT_APP_APPLICATION_SECRET || '77a512a1-2315-43dd-9491-6ce8869cae9d';

// Defines the environment - i.e. test, prod, demo
export const UPDATE_LATENCY = process.env.UPDATE_LATENCY           || 1000;
export const APP_VERSION    = process.env.REACT_APP_VERSION        || 'n/a';
// export const ACTIVE_PROFILE = process.env.REACT_APP_ACTIVE_PROFILE || 'test';
export const ACTIVE_PROFILE = process.env.REACT_APP_ACTIVE_PROFILE || 'test';

export const API_URL = process.env.REACT_APP_API_URL || 'https://test-api.getdabbl.com';
export const AUTH_URL = process.env.REACT_APP_AUTH_URL || 'https://test-auth.getdabbl.com';

export const USER_KEY = "dabbl_user_auth_data";
export const RECAPTCHA_SITEKEY = '';

export const API_SECRET = process.env.REACT_APP_API_SECRET;
export const POLLFISH_API_KEY = process.env.REACT_APP_POLLFISH_API_KEY
  