import React, {useEffect, useState} from 'react'
import {
    Col, Container, Card, CardBody, CardTitle, Form, FormGroup, Label, Input, Row,
    Media, Button, Alert,
    FormText, FormFeedback
} from 'reactstrap';
import moment from "moment";
import {useAuth} from "../contexts/auth";
import user from '../assets/user.png'
import {formatPhoneNumber} from "../helpers";
import './Account.css'

export default function Account() {
    const {api, userInfo, updateUserInfo} = useAuth()
    const [profileImage, setProfileImage] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState('')
    const [mobileNumber, setMobileNumber] = useState('')
    const [providedZip, setProvidedZip] = useState('')
    const [errors, setErrors] = useState({})
    useEffect(() => {
        if (!userInfo) {
            return
        }
        setProfileImage(userInfo.profileImage)
        setFirstName(userInfo.firstName)
        setLastName(userInfo.lastName)
        setEmail(userInfo.email)
        setDateOfBirth(moment(userInfo.dateOfBirth).format('YYYY-MM-DD'))
        setMobileNumber(userInfo.mobileNumber)
        setProvidedZip(userInfo.providedZip)
    }, [userInfo])


    function handleFileUploadChange(e) {
        if (!e.target.files || e.target.files.length === 0) {
            return
        }
        const file = e.target.files[0]
        if (file.size > 1024 * 1024) {
            console.log('image file exceeds 1 mb')
            setErrors({...errors, profileImage: 'File size shouldn\'t exceed 1 Mb.'})
            return
        }
        if (errors.profileImage) {
            setErrors({...errors, profileImage: ''})
        }
        api.uploadProfileImage(file).then((res) => {
            console.log(res)
            setProfileImage(res.data.medium)
        })
    }

    if (!userInfo) {
        return <>Loading...</>
    }

    function areAnyValuesChanged() {
        return profileImage !== userInfo.profileImage ||
            firstName !== userInfo.firstName ||
            lastName !== userInfo.lastName ||
            email !== userInfo.email ||
            (providedZip || '') !== (userInfo.providedZip || '') && ((providedZip || '').length === 5 || (providedZip || '').length === 0)
    }

    function onSubmit(e) {
        e.preventDefault();
        setErrors({...errors, generic: undefined})
        const data = {
            ...(profileImage !== userInfo.profileImage && {profileImage: profileImage}),
            ...(firstName !== userInfo.firstName && {firstName: firstName}),
            ...(lastName !== userInfo.lastName && {lastName: lastName}),
            ...(email !== userInfo.email && {email: email}),
            ...(providedZip !== userInfo.providedZip && {providedZip: providedZip}),
        }
        api.updateUserData(data).then((res) => {
            if (res.status === 200) {
                updateUserInfo(data)
            } else {
                setErrors({
                    ...errors,
                    generic: 'Sorry, we are having a problem completing your request. Please try again later.'
                })
            }
        }).catch(err => setErrors({
            ...errors,
            generic: 'Sorry, we are having a problem completing your request. Please try again later.'
        }))
    }

    return <Container className='m-1'>
        <Card outline body>
            <CardTitle tag='h5'>Account Information</CardTitle>
            <CardBody>
                <Form onSubmit={onSubmit}>
                    <Row>
                        <Col xs='auto' className='mx-auto'>
                            <Media left middle object src={profileImage || user} className='rounded-circle'
                                   style={{maxHeight: '64px', maxWidth: '64px', clipPath: 'circle'}} alt={''}/>
                        </Col>
                    </Row>
                    <FormGroup row>
                        <Col xs='auto' className='mx-auto p-1'>

                            {!profileImage ?
                                <>
                                    <Label for="profile_image" className="btn btn-secondary mx-auto">Upload Profile
                                        Image</Label>
                                    <Input type="file" name="file" id="profile_image" accept='image/*'
                                           onChange={(e) => handleFileUploadChange(e)} style={{display: 'none'}}/>
                                    {errors.profileImage && <Alert color='warning'>{errors.profileImage}</Alert>}
                                </>
                                :
                                <Button color="secondary" onClick={(e) => setProfileImage('')}>Clear</Button>
                            }
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="firstName">First Name</Label>
                        <Input
                            id="firstName"
                            name="firstName"
                            defaultValue={firstName}
                            type="text"
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup row>
                        <Label for="lastName">Last Name</Label>
                        <Input
                            id="lastName"
                            name="lastName"
                            defaultValue={lastName}
                            type="text"
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup row>
                        <Label for="mobileNumber">Phone Number</Label>
                        <Input
                            id="mobileNumber"
                            name="mobileNumber"
                            defaultValue={formatPhoneNumber(mobileNumber)}
                            type="text"
                            disabled
                        />
                    </FormGroup>
                    <FormGroup row>
                        <Label for="email">Email</Label>
                        <Input
                            id="email"
                            name="email"
                            defaultValue={email}
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup row>
                        <Label for='dateOfBirth'>Date of Birth</Label>
                        <Input
                            id="dateOfBirth"
                            name="dateOfBirth"
                            defaultValue={dateOfBirth}
                            type="date"
                            disabled
                        />
                    </FormGroup>
                    <FormGroup row>
                        <Label for="providedZip">Zip</Label>
                        <Input
                            id="providedZip"
                            name="providedZip"
                            defaultValue={providedZip}
                            type="text"
                            maxLength={5}
                            onChange={(e) => setProvidedZip(e.target.value)}
                            onKeyDown={(e) => {
                                if(isNaN(e.key) && e.keyCode !== 8){
                                    e.preventDefault()
                                }
                            }}
                        />
                    </FormGroup>
                    {errors.generic && <Alert color='danger'>{errors.generic}</Alert>}
                    <Button color='primary' disabled={!areAnyValuesChanged()}>Update</Button>
                </Form>
            </CardBody>
        </Card>
        <Container className="text-center">
            <a href='https://getdabbl.com/faqs-more/' className='btn btn-link text-muted' target='_blank'>Help</a>
            <a href='https://getdabbl.com/terms/' className='btn btn-link text-muted' target='_blank'>Terms of Use</a>
            <a href='https://getdabbl.com/privacy-policy/' className='btn btn-link text-muted' target='_blank'>Privacy
                Policy</a>
        </Container>
    </Container>
}