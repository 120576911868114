import React from 'react'
import {Col, Container, Row} from 'reactstrap'
import {useAuth} from "../contexts/auth";
export default function Profile(){
    const {userInfo} = useAuth()
    return <Container>
        <Row>
            <Col>
                <pre>{`User Info: ${!userInfo ? 'Loading...' : JSON.stringify(userInfo, null, 12)}`}</pre>
            </Col>
        </Row>
    </Container>
}