import React from 'react';
import Campaigns from "../components/Campaigns";
import {useAuth} from "../contexts/auth";
import Surveys from "../components/Surveys";

function UserFeed() {
    const {userInfo} = useAuth()

    return (<>
        <Campaigns />
        {userInfo ? <Surveys/> : <>Loading...</> }
    </>
    );
}

export default UserFeed;