import amplitude from 'amplitude-js';
import {ACTIVE_PROFILE, APP_VERSION} from "./config";

const AMPLITUDE_KEY = ACTIVE_PROFILE === "prod" ? "89ee3019f8ac54c4072f63de040d30ca" : "fd7ef418d90831244c2ee5fa202f357f";

export const initAmplitude = () => {
    amplitude.getInstance().init(AMPLITUDE_KEY, null, {
        // logLevel: 'INFO',
        platform: 'Web - Dabbl'
    });
    amplitude.getInstance().setVersionName(APP_VERSION);
};

export const setAmplitudeUserId = userId => {
    amplitude.getInstance().setUserId(userId);
};

export const logAmplitudeRegistrationEvent = () => {
    amplitude.getInstance().logEvent("Registration");
};

export const logAmplitudeFeedRetrievalEvent = (campaigns) => {
    if (!campaigns) {
        return
    }
    let completedCampaignsCount = 0
    let activeCampaignsCount = 0
    let totalConsumerValue = 0.0
    let campaignsData = [];
    let campaignNames = [];
    for (let i = 0; i < campaigns.length; i++) {
        let campaign = campaigns[i]
        if (campaign.campaignComplete === true) {
            completedCampaignsCount++
        } else {
            activeCampaignsCount += 1
            totalConsumerValue += campaign.totalValue
        }
        campaignsData.push({"CampaignName": campaign.name, "State": (campaign.campaignComplete === true) ? "Completed" : "Active", "ConsumerValue": campaign.totalValue})
        campaignNames.push(campaign.name)
    }
    amplitude.getInstance().logEvent("Feed Retrieval", {  "CompletedCampaigns": completedCampaignsCount,
        "ActiveCampaigns": activeCampaignsCount,
        "TotalConsumerValue": totalConsumerValue,
        "Campaigns": campaignsData,
        "CampaignNames": campaignNames
    });
};

export const logAmplitudeCompletedCampaignEvent = (name, value) => {
    amplitude.getInstance().logEvent("Completed Campaign", {"CampaignName": name, "ConsumerValue": value});
};

export const logAmplitudeAccountScreen = () => {
    amplitude.getInstance().logEvent('Account Screen');
};

export const logAmplitudeGiftCardOrder = (cardName, value) => {
    amplitude.getInstance().logEvent('Giftcard Order', {'CardName': cardName, 'Value': value});
};

export const logAmplitudeSocialShare = (to) => {
    amplitude.getInstance().logEvent('Social Share', {"From": 'EmptyScreen', "To": to});
};

export const logExperienceSocialShare = (campaignName, campaignId, sharingUrl, network) => {
    amplitude.getInstance().logEvent('Experience Social Share', {"Experience Name": campaignName, "Experience Id": campaignId, "Share URL": sharingUrl, "Network": network});
};

export const logAmplitudeConclusionLinkClick = (campaignId, campaignName, brandName, url) => {
    amplitude.getInstance().logEvent('Conclusion Link Click', {"Campaign Id": campaignId, "Campaign Name": campaignName, "Brand Name": brandName, "Actual URL": url});
};
