import React, {useState, useEffect} from 'react';
import {useAuth} from "../contexts/auth";
import SurveyTile from "../components/SurveyTile";

export default function SurveyTheoremReach() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [TR, setTheoremReach] = useState(undefined);
    const {userInfo} = useAuth();
    useEffect(() => {
      const theoremScript = document.createElement('script')
      theoremScript.src = 'https://s3.amazonaws.com/cdn.theoremreach/v3/theorem_reach.min.js';
      theoremScript.addEventListener('load', () => setIsLoaded(true))
        document.body.appendChild(theoremScript)
        return ()=>document.body.removeChild(theoremScript)
    }, [])

    function onRewardCenterOpened(){
        console.log('onRewardCenterOpened')
    }

    function onReward(){
        console.log('onReward')
    }

    function onRewardCenterClosed(){
        console.log('onRewardCenterClosed')
    }

    useEffect( () => {
        if (!isLoaded) return
        const theoremReachConfig = {
            apiKey: "205376c0cc5167a9d4afdf545ad1",//"770861e21d181a2b1169fc6c4bf2",
            userId: userInfo ? userInfo.id : '',
            idfa: "", // optional. If iOS, set the Apple IDFA to ensure the most number of surveys are available
            gpsId: "", // optional. If Android, set the Google Advertising ID
            onRewardCenterOpened: onRewardCenterOpened,
            onReward: onReward,
            onRewardCenterClosed: onRewardCenterClosed
        };

        setTheoremReach(new window.TheoremReach(theoremReachConfig));
    }, [isLoaded, userInfo]);

    return <SurveyTile isReady={isLoaded && TR} title='from Theorem Reach' imgAlt='from Theorem Reach'
                       imgSrc='https://storage.googleapis.com/adjoy-app-assets/static/reward-promos/Theorem-0X.jpg' onClick={()=>TR.showRewardCenter()} />

}
