import React, {useState} from 'react';
import {
    Alert, Button,
    Card,
    CardBody, CardFooter,
    CardImg,
    CardTitle,
    Col,
    Container,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Row
} from "reactstrap";
import logoImage from "../assets/dabbl-logo-orange.png";
import {formatPhoneNumber, phoneRegex} from "../helpers";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../contexts/auth";
import {getAuthToken, signupAccount, signupConfirmation, signupVerification} from "../api/auth";
import moment from "moment";
import {Digit} from "../components/Digit/Digit";
import {DigitInputs} from "../components/DigitInput/DigitsInput";

function Register() {
    const {username, setAuthUser} = useAuth();
    console.log(username)
    let navigate = useNavigate();
    const [step, setStep] = useState(0);
    const [requestError, setRequestError] = useState('');
    const [isValidPhone, setIsValidPhone] = useState(true);
    const [shouldValidate, setShouldValidate] = useState(false);
    const [mobileNumber, setMobileNumber] = useState(()=> username || Math.floor(10000000000 + Math.random() * 10000000000));
    const [email, setEmail] = useState("");
    const [smsOptIn, setSmsOptIn] = useState(true);
    const [password, setPassword] = useState("");
    const [code, setCode] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState(()=>moment().subtract(13, 'year').startOf('day'));
    const [nonceToken, setNonceToken] = useState('')

    function onSubmit(e) {
        e.preventDefault();
        if (!isValidPhone){
            setShouldValidate(true)
            return
        }
        switch (step) {
            case 0:
                signupVerification(('' + mobileNumber).replace(/\D/g, ''), moment(dateOfBirth).format('YYYY-MM-DD'), 0.201, 'web', smsOptIn)
                    .then(res=>{
                        if (res.status === 200) {
                            setNonceToken(res.data.nonceToken)
                            setStep(step+1)
                        }
                    })
                break
            case 1:
                signupConfirmation(nonceToken, code)
                    .then(res=>{
                        if (res.status === 200) {
                            if (res.data.exists) {
                                setRequestError('User with the same mobile number is already registered.')
                                return
                            } if (!res.data.successful) {
                                setRequestError('Only US mobile phone number allowed')
                                return
                            }
                            setStep(step+1)
                        }
                    })
                break
            case 2:
                signupAccount(nonceToken, password, email).then(result => {
                    if (result.status === 200) {
                        getAuthToken(('' + mobileNumber).replace(/\D/g, ''), password)
                            .then(result => {
                                if (result.status === 200)
                                    setAuthUser(result.data);
                                navigate('/', {replace: true});
                            })
                    } else {
                        setRequestError('Error occurred on backend side.');
                    }
                })
                    .catch(e => {
                        setRequestError(e.message);
                    });
                break
            default:
                navigate('/', {replace: true});
                break
        }
    }

    return (
        <div>
            <Container>
                <Row>
                    <Col sm={{size: 10, offset: 1}} md={{size: 6, offset: 3}}>
                        <Card>
                            <CardImg top style={{width: '100px', margin: '30px auto', display: 'block'}} src={logoImage}
                                     alt="Logo Image"/>
                            <CardBody>
                                <CardTitle>Registration step {step + 1}</CardTitle>
                                <Form onSubmit={onSubmit}>
                                    {step === 0 && <FormGroup row>
                                        <Label sm={4}>Mobile Number</Label>
                                        <Col sm={8}>
                                            <Input
                                                name="mobileNumber"
                                                id="mobileNumber"
                                                placeholder="+1 (222) 333-4444"
                                                value={mobileNumber}
                                                valid={ isValidPhone }
                                                invalid= { shouldValidate && !isValidPhone}
                                                onChange={ (e) => {
                                                    const newValue = formatPhoneNumber(e.target.value)
                                                    setIsValidPhone(phoneRegex.test(newValue));
                                                    setMobileNumber(newValue)
                                                } }
                                            />
                                        </Col>
                                        <FormFeedback valid>
                                            Mobile number is valid
                                        </FormFeedback>
                                        <FormFeedback>
                                            Invalid entry. Please input a correct phone number.
                                        </FormFeedback>
                                    </FormGroup>
                                    }
                                    {step === 0 &&
                                        <FormGroup row>
                                            <Label sm={4} for='dateOfBirth'>Date of Birth</Label>
                                            <Col sm={8}>
                                                <Input
                                                    id="dateOfBirth"
                                                    name="dateOfBirth"
                                                    defaultValue={dateOfBirth.format('YYYY-MM-DD')}
                                                    placeholder="date of birth"
                                                    type="date"
                                                    onChange={(e) => setDateOfBirth(e.target.value)}
                                                />
                                                <FormFeedback>Incorrect Date of Birth</FormFeedback>
                                            </Col>
                                        </FormGroup>
                                    }
                                    {step === 0 &&
                                        <FormGroup row>
                                            <Label sm={4} for='email'>Email</Label>
                                            <Col sm={8}>
                                                <Input
                                                    id="email"
                                                    name="email"
                                                    placeholder="email"
                                                    type="email"
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                                <FormFeedback>Incorrect email</FormFeedback>
                                            </Col>
                                        </FormGroup>
                                    }
                                    {step === 0 &&
                                        <FormGroup row>
                                            <Col sm={1} className='g-0'>
                                                <Input type="checkbox" id="smsOptIn" name="smsOptIn" className='float-end'
                                                       defaultChecked={smsOptIn}
                                                       onChange={(e) => setSmsOptIn(e.target.checked)}/>
                                            </Col>
                                            <Col sm={11}>
                                                <Label for="smsOptIn" check>Please also send me occasional texts related to promotions or important app updates</Label>
                                            </Col>
                                        </FormGroup>
                                    }
                                    {step === 1 &&
                                        <FormGroup row>
                                            <Label sm={4} for="code">Sms Code</Label>
                                            <Col sm={8}>
                                                <DigitInputs onDigitsChange={(data) => setCode(data.asString)}>
                                                    <Digit />
                                                    <Digit />
                                                    <Digit />
                                                    <Digit />
                                                </DigitInputs>
                                            </Col>
                                            <Col sm={12} className='text-center'>
                                                <Button color="link" onClick={(e)=>{e.preventDefault(); setStep(0)}}>Start Over</Button>
                                            </Col>
                                        </FormGroup>
                                    }
                                    {step === 2 &&
                                        <FormGroup row>
                                            <Label sm={4} for="examplePassword">Password</Label>
                                            <Col sm={8}>
                                                <Input
                                                    type="password"
                                                    name="password"
                                                    id="password"
                                                    placeholder="********"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                            </Col>
                                        </FormGroup>
                                    }
                                    {requestError &&
                                        <Col>
                                            <Alert color="danger">
                                                {requestError}
                                            </Alert>
                                        </Col>
                                    }
                                    <Button color="primary" className='float-end'>{step === 2 ? 'Submit' : 'Next'}</Button>
                                </Form>
                            </CardBody>
                            <CardFooter>
                                <div>Already have an account? Click <a href='/login'>here</a> to sign in.</div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Register;