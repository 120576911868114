import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {Campaign as CampaignComponent} from 'dabbl-react-components'
import {useAuth} from "../contexts/auth";
import {logAmplitudeConclusionLinkClick} from "../amplitude";

export default function Campaign(){
    const navigate = useNavigate()
    let { id } = useParams();
    const {api, campaigns, setCampaigns, incrementBalance, updateCampaign} = useAuth()
    const [answers, setAnswers] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [timeSpent, setTimeSpent] = useState(0)
    const campaign = campaigns && campaigns.find(c=>c.id === id)

    useEffect(()=>{
        if (!campaigns || campaigns.length === 0){
            api.getExperiences().then(res=>{
                if (res.status === 200) {
                    setCampaigns(res.data.data)
                }
            })
        }
    }, [])
    function handleEngagementAnswered(engagementIndex, answer, duration){
        const engagement = campaign.engagements[engagementIndex];
        const newAnswer = {
            answer: answer,
            campaignId: campaign.id,
            engagementId: engagement.id,
            // engagementOrdinal: engagement.ordinal,
            timeSpent: duration
        }
        setTimeSpent(timeSpent + duration)
        setAnswers([...answers, newAnswer])
    }
    function handleAllEngagementsAnswered() {
        api.submitAnswers(campaign, answers, timeSpent)
            .then(() => incrementBalance(campaign.totalValue))
            .then(() => updateCampaign({...campaign, isComplete: true}))
    }
    function handleShareCampaign(network){
        // logExperienceSocialShare(campaign.name, campaign.id, campaign.previewDL, network)
    }

    return <div className="campaign-page vertical-center">
        {campaign ?
            <CampaignComponent
                campaign={campaign}
                retailerName="Dabbl"
                engagementStartIndex={0}
                onEngagementAnswered={(engagementIndex, answer, duration) => handleEngagementAnswered(engagementIndex, answer, duration)}
                onAllEngagementsAnswered={() => handleAllEngagementsAnswered()}
                onDoneCampaign={() => navigate('/', {replace:true})} // occurred only if revisiting completed campaign
                onShareButtonClick={(network) => handleShareCampaign(network)}
                onSkipShareCampaign={() => navigate('/', {replace:true})}
                onCloseCampaign={(engagementIndex, stepDuration, isCompleted) => navigate('/', {replace:true})}
                onCampaignAssetsCached={(success) => { setIsLoading(false) }}
                onConclusionLinkClick={(url) => {
                    logAmplitudeConclusionLinkClick(campaign.id, campaign.name, campaign.brandName, url);
                    api.trackCtaClick(campaign.id);
                }}
            />
            :
            <div>Loading...</div>
        }
    </div>
}