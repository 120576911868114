import React, { useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap';
import {useNavigate} from "react-router-dom";
import {CampaignPreviewCard} from 'dabbl-react-components'
import {useAuth} from "../contexts/auth";
import './Campaigns.css'


export default function Campaigns(){
    const navigate = useNavigate()
    const {api,campaigns, setCampaigns} = useAuth()
    useEffect(()=> {
        api.getExperiences().then(res=>{
            if (res.status === 200) {
                setCampaigns(res.data.data)
            }
        })
    }, [])
    function handleCampaignClick(id){
        navigate(`/campaigns/${id}`)
    }
    return <Container className='Campaigns m-2'>
        <Row>
            {campaigns && campaigns.length > 0 ? campaigns.map((c, i) =>
                <Col
                    key={c.id}
                    xs={{size: 10, offset: 1}}
                    sm={{size: 8, offset: 2}}
                    md={{size: 6, offset: 0}}
                    lg={{size: 4, offset: 0}}
                    xl={{size: 3, offset: 0}}
                >
                    <CampaignPreviewCard
                        retailerName={'Dabbl'}
                        brandName={c.brandName}
                        brandColor={c.brandColor}
                        totalValue={c.totalValue}
                        text={c.feedtext}
                        tags={[]}
                        imgSrc={c.feedimageurl}
                        imgAlt={c.brandName}
                        isCompleted={c.campaignComplete}
                        onClick={() => handleCampaignClick(c.id)}
                    />
                </Col>
            ) :
                <Col>
                    Loading...
                </Col>
            }
        </Row>
    </Container>
}