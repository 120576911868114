import React, {useEffect, useState} from 'react'
import {useAuth} from "../contexts/auth";
import {POLLFISH_API_KEY} from "../config";
import SurveyTile from "../components/SurveyTile";

export default function SurveyPollfish(){
    const {userInfo} = useAuth()
    const [isLoaded, setIsLoaded] = useState(false);
    const [isReady, setIsReady] = useState(false);

    function customSurveyClosed(){
        console.log("user closed the survey");
    }

    function customUserNotEligible(){
        console.log("user is not eligible");
    }

    function customSurveyFinished(data){
        console.log(`
      pollfish survey has finished with revenue: ${data.survey_price},
      survey_loi: ${data.survey_loi},
      survey_ir: ${data.survey_ir},
      survey_class: ${data.survey_class},
      reward_name: ${data.reward_name},
      reward_value: ${data.reward_value},
    `);
    }

    function customCloseAndNoShow(){
        console.log("close and hide the indicator");
    }

    function customSurveyAvailable(data){
        console.log(`
      pollfish survey is available with revenue: ${data.revenue},
      survey format playful: ${data.playful},
      survey_loi: ${data.survey_loi},
      survey_ir: ${data.survey_ir},
      survey_class: ${data.survey_class},
      reward_name: ${data.reward_name},
      reward_value: ${data.reward_value},
      remaining_completes: ${data.remaining_completes},
    `);
    }

    function customSurveyNotAvailable(){
        console.log("survey not available");
    }
    function pollfishReady(){
        console.log("survey pollfishReady");
        setIsReady(true)
    }
    useEffect(() => {
        window.pollfishConfig = {
            api_key: POLLFISH_API_KEY,
            user_id: userInfo.id,
            indicator_position: "BOTTOM_RIGHT",
            debug: true,
            offerwall: true,
            request_uuid: "string_uuid",
            alwaysReturnContent: false, // show the iframe even when no survey is found
            closeCallback: customSurveyClosed,
            userNotEligibleCallback: customUserNotEligible,
            closeAndNoShowCallback: customCloseAndNoShow,
            surveyCompletedCallback: customSurveyFinished,
            surveyAvailable: customSurveyAvailable,
            surveyNotAvailable: customSurveyNotAvailable,
            ready: pollfishReady
    };
        const surveyScript = document.createElement('script')
        surveyScript.src = 'https://storage.googleapis.com/pollfish_production/sdk/webplugin/pollfish.min.js';
        surveyScript.addEventListener('load', () => setIsLoaded(true))
        document.body.appendChild(surveyScript)
        return ()=>document.body.removeChild(surveyScript)
    }, [userInfo.id])
    useEffect( () => {
        if (!isLoaded) return

    }, [isLoaded]);


    return <SurveyTile isReady={isLoaded && isReady} title='from Pollfish' imgAlt='from Pollfish' imgSrc='https://storage.googleapis.com/adjoy-app-assets/static/reward-promos/Pollfish-0X.jpg' onClick={()=>window.Pollfish.showFullSurvey()} />
}