import axios from "axios";
import qs from 'qs';
import {API_SECRET, API_URL, APPLICATION_ID, APPLICATION_SECRET, AUTH_URL} from "../config";
import hmacSHA1 from 'crypto-js/hmac-sha1';
import Base64 from 'crypto-js/enc-base64';


export function getAuthToken(username, password) {
  const token = window.btoa(APPLICATION_ID+':'+APPLICATION_SECRET);
  const data = {
    username: String(username),
    password: String(password),
    grant_type: 'password',
    scope: 'APIv3.read APIv3.write'
  }
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    'Accept': 'application/json',
    'Authorization': (token) ? `Basic ${token}` : '',
  };
  return axios.post( `${AUTH_URL}/oauth/token`, qs.stringify(data), { headers: headers})
}

// {
//   "mobileNumber": mobileNumber,
//     "dateOfBirth": "2000-01-01",
//     "amount": 0.50,
//     "adid": "e60919ee-e9c6-43dd-aeeb-ae550b25c813",
//     "deviceId": "33344142",
//     "smsOptIn": true
// }
export function signupVerification(mobileNumber, dateOfBirth, amount, deviceId, smsOptIn) {
  // const hasDateOfBirth = /^\d{4}-\d{2}-\d{2}$/;
  //
  // if (!mobileNumber || mobileNumber.length < 10 || !hasDateOfBirth.test(dateOfBirth))
  //   return Promise.reject('Cannot send sms code with invalid mobile number or date of birth.');
  //
  // if (mobileNumber[0] !== '1') {
  //   mobileNumber = '1' + mobileNumber;
  // }
  const stringData = JSON.stringify({mobileNumber, dateOfBirth, amount, deviceId, smsOptIn})
  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Signature': encodeURIComponent(Base64.stringify(hmacSHA1(stringData, API_SECRET)))
  };

  return axios.post( `${API_URL}/app/v1/pub/signup/verification`, stringData, { headers: headers})
}

export function signupConfirmation(nonceToken, code) {
  return axios.put( `${API_URL}/app/v1/pub/signup/confirmation`, {code, nonceToken})
}

export function signupAccount(nonceToken, password, email) {
  return axios.post( `${API_URL}/app/v1/pub/signup/account`, {password, email, nonceToken})
}

export function resetPasswordVerification(mobileNumber) {
  return axios.post( `${API_URL}/app/v1/pub/resetPassword/verification`, {mobileNumber})
}

export function resetPasswordConfirmation(code, nonceToken) {
  return axios.post( `${API_URL}/app/v1/pub/resetPassword/confirmation`, {code, nonceToken})
}

export function resetPassword(password, nonceToken) {
  return axios.post( `${API_URL}/app/v1/pub/resetPassword`, {password, nonceToken})
}

