import React  from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet, useLocation } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import '../node_modules/dabbl-react-components/build/styles.css';
import Login from './pages/Login';
import Register from './pages/Register';
import UserFeed from './pages/UserFeed';
import {useAuth, AuthProvider} from "./contexts/auth";
import {APP_VERSION, USER_KEY} from './config'
import Navigation from "./Navigation";
import Profile from "./pages/Profile";
import Campaign from "./pages/Campaign";
import Account from "./pages/Account";
import axios from "axios";

const PrivateRoute = () => {
  const { authUser } = useAuth();
  let location = useLocation();
  return authUser ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace  />;
}

function App() {
  return (
      <AuthProvider>
        <Router>
          <div>
            <Navigation/>
            <Routes>
              <Route exact path='/' element={<PrivateRoute/>}>
                <Route exact path='/' element={<UserFeed/>}/>
                <Route exact path="/profile" element={<Profile/>} />
                <Route exact path="/account" element={<Account/>} />
              </Route>
              <Route exact path="/login" element={<Login/>} />
              <Route exact path="/register" element={<Register/>} />
              <Route exact path="/campaigns/:id" element={<Campaign/>} />
              <Route path="/" element={<Navigate to="/login" />}/>
            </Routes>
            <div className="fixed-bottom font-italic font-weight-light">Build: {APP_VERSION}</div>
          </div>
        </Router>
      </AuthProvider>
  );
}

export default App;
