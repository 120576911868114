import React, { createContext, useContext, useEffect, useReducer, useState, useMemo } from 'react';
import {USER_KEY} from "../config";
import {getAppApi} from "../api/AppApi";
const AUTH_CLEAR_USER = 'clear_user';
const AUTH_SET_USERNAME = 'set_username';
const AUTH_SET_AUTH_USER = 'set_auth_user';
const SET_USER_INFO = 'set_user_info';
const UPDATE_USER_INFO = 'update_user_info';
const SET_USER_BALANCE = 'set_user_balance';
const INCREMENT_USER_BALANCE = 'increment_user_campaigns';
const SET_USER_CAMPAIGNS = 'set_user_campaigns';
const UPDATE_USER_CAMPAIGN = 'update_user_campaign';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

const reducer = (state, action) => {
    console.log('running reducer with action ' + action.type)
    switch (action.type){
        case AUTH_CLEAR_USER:
            localStorage.removeItem(USER_KEY);
            return {username: state.username}
        case AUTH_SET_USERNAME:
            return {...state, username: action.payload}
        case AUTH_SET_AUTH_USER:
            localStorage.setItem(USER_KEY, JSON.stringify(action.payload));
            return {authUser: action.payload}
        case SET_USER_INFO:
            return {...state, username: action.payload ? action.payload.name : '', userInfo: action.payload}
        case UPDATE_USER_INFO:
            return {...state, userInfo: {...state.userInfo, ...action.payload} }
        case SET_USER_BALANCE:
            return {...state, balance: action.payload}
        case INCREMENT_USER_BALANCE:
            return {...state, balance: state.balance + action.payload}
        case SET_USER_CAMPAIGNS:
            return {...state, campaigns: action.payload}
        case UPDATE_USER_CAMPAIGN:
            return {...state, campaigns: state.campaigns.map(c => c.id === action.payload.id ? action.payload : c)}
        default: return state
    }
}

export const AuthProvider = ({children}) => {
    const [state, dispatch]  = useReducer(reducer, {
        username: '',
        authUser: null,
        userInfo: {},
        balance: null,
        campaigns: []
    })

    const clearUser = () => dispatch({type: AUTH_CLEAR_USER});
    const setUsername = (username) => dispatch({type: AUTH_SET_USERNAME, payload: username});
    const setAuthUser = (user) => dispatch({type: AUTH_SET_AUTH_USER, payload: user});
    const setUserInfo = (userInfo) => dispatch({type: SET_USER_INFO, payload: userInfo});
    const updateUserInfo = (userInfo) => dispatch({type: UPDATE_USER_INFO, payload: userInfo});
    const setBalance = (balance) => dispatch({type: SET_USER_BALANCE, payload: balance});
    const incrementBalance = (value) => dispatch({type: INCREMENT_USER_BALANCE, payload: value});
    const setCampaigns = (campaigns) => dispatch({type: SET_USER_CAMPAIGNS, payload: campaigns});
    const updateCampaign = (campaign) => dispatch({type: UPDATE_USER_CAMPAIGN, payload: campaign});

    if (!state.authUser && localStorage.getItem(USER_KEY) != null) {
        const authUser = JSON.parse(localStorage.getItem(USER_KEY))
        console.log('Auth user already in storage')
        console.log(authUser)
        if (authUser) {
            setAuthUser(authUser)
        }
    }

    const api = useMemo(()=> getAppApi(null, state.authUser, () => clearUser()),
        state.authUser)
    
    console.log('authProvider render')
    // if (authUser && (!userInfo || !balance || !username)){
    //     return
    // }
    return (
    <AuthContext.Provider value={{ api: api, ...state, clearUser, setUsername, setAuthUser, setUserInfo, updateUserInfo, setBalance, incrementBalance, setCampaigns, updateCampaign }}>
        {children}
    </AuthContext.Provider>
    )
}