
export const phoneRegex = /^(\+1 |)?(\(\d{3}\))( \d{3})(-\d{4})$/;

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
}

export function formatPhoneNumber(phoneNumberString) {
  const match = ('' + phoneNumberString).replace(/\D/g, '');
  const part0 = match.length > 0 ? `+${match.substring(0,1)} ` : ''
  const part1 = match.length > 1 ? `(${match.substring(1,4)}${match.length > 4 ? ')' : ''}` : ''
  const part2 = match.length > 4 ? ` ${match.substring(4, 7)}` : ''
  const part3 = match.length > 7 ? `-${match.substring(7, 11)}` : ''
  return `${part0}${part1}${part2}${part3}`
}