import React, {useState} from 'react';
import {useNavigate, useLocation} from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody, CardFooter,
  CardImg,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import {useAuth} from "../contexts/auth";
import logoImage from '../assets/dabbl-logo-orange.png';
import {getAuthToken} from "../api/auth";
import {formatPhoneNumber, phoneRegex} from "../helpers";


export default function Login() {
  const { username: defaultUserName, setAuthUser } = useAuth();
  let navigate = useNavigate();
  let location = useLocation();
  let from = location.state?.from?.pathname || "/";
  const [requestError, setRequestError] = useState('');
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [shouldValidate, setShouldValidate] = useState(false);
  const [username, setUsername] = useState(formatPhoneNumber(defaultUserName) || '+1');
  const [password, setPassword] = useState("");

  function onSubmit(e) {
    e.preventDefault();
    if (!isValidPhone || password.length === 0){
      setShouldValidate(true)
      console.log('phone or password invalid')
      return
    }
    getAuthToken(('' + username).replace(/\D/g, ''), password)
        .then(result => {
      if (result.status === 200) {
        setAuthUser(result.data);
        navigate(from, { replace: true });
      } else {
        setRequestError('Error occurred on backend side.');
      }
    }).catch(e => {
      if (e.response && e.response.status === 401) {
        setRequestError('Unknown user credentials, please contact someone that can give you proper credentials');
      } else {
        setRequestError(e.message);
      }
    });
  }
  console.log(username)
  return (
      <Container>
        <Row>
          <Col sm={{ size: 10, offset: 1 }} md={{ size: 6, offset: 3 } }>
            <Card>
              <CardImg top style={{width: '100px', margin: '30px auto', display: 'block'}} src={logoImage} alt="Logo Image" />
              <CardBody>
                <CardTitle>Sign In Form</CardTitle>
                <Form onSubmit={onSubmit}>
                  <Col>
                    <FormGroup>
                      <Label>Username</Label>
                      <Input
                          name="username"
                          id="username"
                          placeholder="+1 (222) 333-4444"
                          value={ username }
                          valid={ isValidPhone }
                          invalid= { shouldValidate && !isValidPhone}
                          onChange={ (e) => {
                            const newValue = formatPhoneNumber(e.target.value)
                            setIsValidPhone(phoneRegex.test(newValue));
                            setUsername(newValue)
                          } }
                      />
                      <FormFeedback valid>
                        Phone is valid
                      </FormFeedback>
                      <FormFeedback>
                        Invalid entry. Please input a correct phone number.
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="examplePassword">Password</Label>
                      <Input
                          type="password"
                          name="password"
                          id="password"
                          placeholder="********"
                          value={ password }
                          onChange={ (e) => setPassword(e.target.value) }
                      />
                    </FormGroup>
                  </Col>
                  {requestError &&
                      <Col>
                        <Alert color="danger">
                          {requestError}
                        </Alert>
                      </Col>
                  }
                  <Button color="primary" className='float-end'>Submit</Button>
                </Form>
              </CardBody>
              <CardFooter>
                <div>Don't have an account? Click <a href='/register'>here</a> to register.</div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
  );
}